import * as chartTypes from '../../constants/chartTypes';
import { isPaletteId, isTheme } from './colors';
export const DATAWELL = 'DATAWELL';
export const PIVOT = 'PIVOT';
export const CROSS_TAB = 'CROSS_TAB';
export const SLICED = 'SLICED';
export const TABLE = 'TABLE';
export const XY = 'XY';
export const WATERFALL = 'WATERFALL';
export const FUNNEL = 'FUNNEL';
export const SANKEY = 'SANKEY';
export const GAUGE = 'GAUGE';
export const VisualizationTypes = {
  DATAWELL,
  PIVOT,
  CROSS_TAB,
  SLICED,
  TABLE,
  XY,
  WATERFALL,
  FUNNEL,
  SANKEY,
  GAUGE
};
export const SupportedChartTypes = {
  PIE: chartTypes.PIE,
  DONUT: chartTypes.DONUT,
  GAUGE: chartTypes.GAUGE
};
export const SupportedSlicedChartTypes = {
  PIE: SupportedChartTypes.PIE,
  DONUT: SupportedChartTypes.DONUT
};
export const ChartTypeVisualizationTypeMap = {
  [SupportedChartTypes.PIE]: VisualizationTypes.SLICED,
  [SupportedChartTypes.DONUT]: VisualizationTypes.SLICED,
  [SupportedChartTypes.GAUGE]: VisualizationTypes.GAUGE
};
export const isChartTypeColorTheme = (color = {}) => color.theme !== undefined && isTheme(color.theme) || color.custom !== undefined && !!color.custom;
export const isChartTypeColorPalette = (color = {}) => color.paletteId !== undefined && isPaletteId(color.paletteId);
export const isGaugeChart = chart => {
  return chart.chartType === SupportedChartTypes.GAUGE;
};
export const isSlicedChart = chart => {
  return chart.chartType in SupportedSlicedChartTypes;
};