import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { getDashboardDataSources } from 'platform-dashboard-ui/lib/dashboardDataSourceUtils';
import { useDataSourceMetaWithLoading } from 'platform-dashboard-ui/hooks/use-data-source-metadata';
import { useDataSourceProperties } from 'platform-dashboard-ui/hooks/use-data-source-properties';
import { dashboardReportsActions } from '../ducks/dashboard-reports';
import { initializeDashboardFilters } from '../ducks/applied-dashboard-filters';
import { getSavedFilterSetIdQueryParam } from '../lib/dashboardFilters/dashboard-filter-query-params-util';
import { useFetchEmailOnlyFilterSet } from '../lib/savedDashboardFilters/useFetchEmailOnlyFilterSet';

// TODO split out into another data fetching hook once property metadata store API is ready
export const useFetchDataSourcesForDashboard = dashboard => {
  const dataSourceIds = getDashboardDataSources(dashboard);
  const {
    dataSourceMetaData: dataSourcesMetadata,
    isLoading: isDataSourceMetaLoading
  } = useDataSourceMetaWithLoading(dataSourceIds);
  const {
    propertiesByDataSource: dataSourcesProperties,
    isLoading: isDataSourcePropertiesLoading
  } = useDataSourceProperties(dataSourceIds);
  const isDataSourceDataLoading = isDataSourceMetaLoading || isDataSourcePropertiesLoading;
  const isLoaded = !isDataSourceDataLoading;
  return {
    isLoaded,
    dataSourcesMetadata,
    dataSourcesProperties
  };
};
export const useInitializeFilters = immutableDashboard => {
  const dashboard = useMemo(() => immutableDashboard.toJS(), [immutableDashboard]);
  const dashboardId = dashboard.id;
  const quickFilters = dashboard.filterGroups;
  const history = useHistory();
  const filterSetIdOverride = getSavedFilterSetIdQueryParam({
    history
  });
  const {
    emailFilters,
    hasLoaded: hasEmailOnlyFilterOverrideLoaded,
    hasError: hasEmailOnlyFilterOverrideError,
    frequency,
    useFiscalYear
  } = useFetchEmailOnlyFilterSet(dashboard, filterSetIdOverride);

  /** dashboard id of filters to initialize */
  const [initializedDashboardId, setInitializedDashboardId] = useState();
  const dispatch = useDispatch();
  const hasInitializedFilters = initializedDashboardId === dashboardId;
  const shouldIntialize = !hasInitializedFilters;
  const shouldInitializeSavedFilters = !!filterSetIdOverride;
  const shouldIntializeBEFilters = hasInitializedFilters;
  const filterSets = useMemo(() => hasEmailOnlyFilterOverrideLoaded && filterSetIdOverride ? [{
    name: 'Email Only',
    dashboardId,
    defaultFilter: false,
    id: filterSetIdOverride,
    filters: emailFilters,
    frequency,
    useFiscalYear
  }] : [], [dashboardId, emailFilters, filterSetIdOverride, frequency, hasEmailOnlyFilterOverrideLoaded, useFiscalYear]);
  useEffect(() => {
    if (shouldIntializeBEFilters) {
      dispatch(dashboardReportsActions.initializeDashboardReports({
        dashboard
      }));
    }
    //  prevent running this whenever dashboard object changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shouldIntializeBEFilters]);
  const {
    isLoaded: areDataSourceDependenciesLoaded
  } = useFetchDataSourcesForDashboard(dashboard);
  useEffect(() => {
    if (initializedDashboardId && dashboardId !== initializedDashboardId) {
      setInitializedDashboardId(undefined);
    }
  }, [dashboardId, initializedDashboardId]);
  useEffect(() => {
    if (!shouldIntialize) {
      return;
    }
    if (!areDataSourceDependenciesLoaded) {
      return;
    }
    if (!shouldInitializeSavedFilters || hasEmailOnlyFilterOverrideError) {
      dispatch(initializeDashboardFilters({
        dashboardId,
        history,
        quickFilters
      }));
      setInitializedDashboardId(dashboardId);
      return;
    }
    if (!hasEmailOnlyFilterOverrideLoaded) {
      return;
    }
    dispatch(initializeDashboardFilters({
      dashboardId,
      history,
      filterSetId: filterSetIdOverride,
      filterSets,
      quickFilters
    }));
    setInitializedDashboardId(dashboardId);
    return;
  }, [areDataSourceDependenciesLoaded, dashboardId, dispatch, filterSetIdOverride, filterSets, hasEmailOnlyFilterOverrideError, hasEmailOnlyFilterOverrideLoaded, history, quickFilters, shouldInitializeSavedFilters, shouldIntialize]);
  return hasInitializedFilters;
};