// @ts-expect-error Untyped dependency
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { TEXT } from 'customer-data-objects/property/PropertyFieldTypes';
import { BOOLEAN, DATE, DATE_TIME, ENUMERATION, NUMBER, STRING } from 'customer-data-objects/property/PropertyTypes';
import { Map as ImmutableMap, OrderedSet } from 'immutable';
const PHONE_NUMBER = 'phone_number';
const dateDefaults = OrderedSet.of(Operators.InRollingDateRange, Operators.Equal, Operators.Less, Operators.Greater, Operators.InRange, Operators.GreaterRolling, Operators.LessRolling, Operators.Known, Operators.NotKnown);

/**
 * Mapping between a property type and available operators.
 *
 * This file is copied from customer-data-filters to allow reporting to validate operators before they show up in the reporting filters UI.
 * Related: https://git.hubteam.com/HubSpot/customer-data-filters/blob/master/customer-data-filters/static-1.43685/js/converters/contactSearch/ContactSearchTypeToOperator.js#L28
 */
const propertyTypeToOperator = ImmutableMap({
  [PHONE_NUMBER]: OrderedSet.of(Operators.Known, Operators.NotKnown),
  [BOOLEAN]: OrderedSet.of(Operators.Equal, Operators.NotEqual, Operators.Known, Operators.NotKnown, Operators.EqualAny, Operators.NotEqualAny),
  [DATE]: dateDefaults,
  [DATE_TIME]: dateDefaults,
  [ENUMERATION]: OrderedSet.of(Operators.In, Operators.NotIn, Operators.Known, Operators.NotKnown),
  [NUMBER]: OrderedSet.of(Operators.Equal, Operators.NotEqual, Operators.Greater, Operators.GreaterOrEqual, Operators.Less, Operators.LessOrEqual, Operators.InRange, Operators.Known, Operators.NotKnown),
  [STRING]: OrderedSet.of(Operators.WildCardEqual, Operators.NotWildCardEqual, Operators.Known, Operators.NotKnown),
  [TEXT]: OrderedSet.of(Operators.WildCardEqual, Operators.NotWildCardEqual, Operators.Known, Operators.NotKnown)
});
export const getOperatorsForPropertyType = type => {
  return propertyTypeToOperator.get(type);
};