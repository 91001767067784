import enviro from 'enviro';
import { getDataType } from 'reporting-data/report/reportGetters';

// @ts-expect-error untyped dependency
import { isJourneyLikeExternal } from '../../lib/report-logic';
const trytoSetLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch (e) {
    return false;
  }
};
const storeJourneyConfigInLocalStorage = report => trytoSetLocalStorage('journeys::open-report', JSON.stringify(report.toJS()));
const EVENT_ID_BY_ENV = {
  QA: {
    SENT: '4-1026549',
    DELIVERED: '4-1027098',
    CLICKED: '4-1028168'
  },
  PROD: {
    SENT: '4-1719453',
    DELIVERED: '4-1721168',
    CLICKED: '4-1722276'
  }
};
const SMS_FUNNEL_EVENTS = ['SENT', 'DELIVERED', 'CLICKED'];
const JOURNEY_LIKE_EXTERNAL_STORAGE_STRATEGIES = {
  EXT_SMS_ANALYZE_FUNNEL_CHART: () => {
    const environment = enviro.isProd() ? 'PROD' : 'QA';
    const encodedInput = JSON.stringify(SMS_FUNNEL_EVENTS.map(event => EVENT_ID_BY_ENV[environment][event]));
    return trytoSetLocalStorage('cbe-input', encodedInput);
  }
};
export const maybePassJourneyViaLocalStorage = (report, {
  loadConfig
} = {
  loadConfig: false
}) => {
  if (isJourneyLikeExternal(report)) {
    return JOURNEY_LIKE_EXTERNAL_STORAGE_STRATEGIES[getDataType(report)]();
  } else {
    return loadConfig && storeJourneyConfigInLocalStorage(report);
  }
};