'use es6';

import { CROSS_OBJECT, ATTRIBUTION, ATTRIBUTION_TOUCH_POINTS, CONTACT_CREATE_ATTRIBUTION, CRM_OBJECT, DEAL_CREATE_ATTRIBUTION, EXTERNAL, ENGAGEMENT } from 'reporting-data/constants/dataTypes';
import { isRelationalReport } from 'reporting-data/tsTypes/reportTypes';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from 'reporting-data/constants/objectCoordinates';
import { ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS } from 'reporting-data/crmObjects/utils';
import { getRawDataType } from 'reporting-data/report/configReportGetters';
import { getTemplateKey } from 'reporting-data/report/reportGetters';
import { COMMERCE_PAYMENTS } from 'reporting-data/constants/dataTypes/inboundDb';
import { getTableList, getDataSourceId } from 'reporting-snowflake/relational/utils/table-utils';
import { SourceTypes, TableDescription } from 'reporting-snowflake/relational/schema/table-records';
import { ENGAGEMENT as ENGAGEMENT_OBJECT_ID } from 'reporting-snowflake/relational/metadata/objectTypes';
import compose from 'transmute/compose';
import curry from 'transmute/curry';
import either from 'transmute/either';
import both from 'transmute/both';
import { defaultTo, isNotNil, isString, strictEquals } from '../../private/js-util';
import { getChartType, getConfigCustomized, getCreatedByStr, getCrossObjectDataTypes, getCustomized, getDataType, getObjectTypeId, getOwnerIdStr, getReportDashboardInfo, getReportDefinition, getVisualType, setLimit as setLimitV1 } from './report-schema';
import { complement } from 'dashboard-lib/private/js-util';
import WIDGET_CONFIG_TYPES from './report-config-types';
const DEAL_CREATE_ATTRIBUTION_OBJECT_TYPE_ID = DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(DEAL_CREATE_ATTRIBUTION);
export const setNameComplete = (report, name) => {
  let updated = report.set('name', name);
  if (report.get('chartType') === 'DATA_LAYER') {
    updated = updated.setIn(['config', 'name'], name);
  }
  return updated;
};

/**
 * @param {String | Integer} userId
 * @param report
 */
export const isUserOwner = curry((userId, report) => {
  const ownerId = getOwnerIdStr(report);
  return isNotNil(userId) && isNotNil(ownerId) && ownerId === String(userId);
});
export const isUserCreator = curry((userId, report) => {
  const createdBy = getCreatedByStr(report);
  return isNotNil(userId) && isNotNil(createdBy) && createdBy === String(userId);
});
export const isV2 = report => defaultTo(false, getReportDefinition(report));
export const isCustom = report => defaultTo(false, getConfigCustomized(report)) || defaultTo(false, getCustomized(report));
export const isCustomDatasetReport = report => report.getIn(['reportDefinition', 'table', 'type']) === 'HUBSPOT_DATASET';
const isDataType = type => compose(strictEquals(type), getDataType);
const isObjectTypeId = type => compose(strictEquals(type), getObjectTypeId);
export const setLimit = (limit, report) => !isV2(report) ? setLimitV1(limit, report) : report;
export const getChartVisualType = report => isV2(report) ? getVisualType(report) : getChartType(report);
export const isCrossObject = isDataType(CROSS_OBJECT);
export const isAttributionReport = isDataType(ATTRIBUTION);
export const isRevenueAttributionReport = isDataType(ATTRIBUTION_TOUCH_POINTS);
export const isContactCreateAttributionReport = isDataType(CONTACT_CREATE_ATTRIBUTION);
export const isDealCreateAttributionReport = both(isDataType(CRM_OBJECT), isObjectTypeId(DEAL_CREATE_ATTRIBUTION_OBJECT_TYPE_ID));
export const isAttributionPlatformLike = report => isRevenueAttributionReport(report) || isContactCreateAttributionReport(report) || isDealCreateAttributionReport(report);
export const isAttributionLike = either(isAttributionReport, curry(isAttributionPlatformLike));
export const isDashboardCompatible = complement(isAttributionReport);
export const getAllDataTypes = report => {
  const reportType = getDataType(report);
  const defaultReturn = [];
  if (isCrossObject(report)) {
    return getCrossObjectDataTypes(report) || defaultReturn;
  }
  return reportType ? [reportType] : defaultReturn;
};
export const isValidReportName = name => isString(name) && !!name.trim().length;
export const isNoteWidget = report => report.getIn(['config', 'configType']) === WIDGET_CONFIG_TYPES.NOTE;
export const isIframeWidget = report => report.getIn(['config', 'configType']) === WIDGET_CONFIG_TYPES.IFRAME;
export const isStaticWidget = report => isNoteWidget(report) || isIframeWidget(report);
export const isStandalone = report => getReportDashboardInfo(report).length === 0;
export const getSnowflakeReportDataSources = report => {
  const tableDescription = TableDescription(report.getIn(['reportDefinition', 'table']).toJS());
  if (tableDescription.type === SourceTypes.HUBSPOT_DATASET) {
    // TODO: Once we allow dataset based reports to be filtered, we will need to return the dataset id here so that our call
    // to fetch the datasource metadata will know to load this dataset definition by id
    return [];
  }
  return getTableList(tableDescription).map(table => getDataSourceId(table)).filter(objectTypeId => !!objectTypeId).toJS();
};

// TODO can we replace this with https://git.hubteam.com/HubSpot/reporting/blob/master/reporting-data/static-1.57848/js/report/configReportGetters.ts#L60
const getConfigReportDataSources = report => {
  const dataType = getRawDataType(report);
  if (dataType === EXTERNAL) {
    return [getDataType(report)];
  }
  const objectTypeId = (() => {
    if (dataType === CRM_OBJECT) {
      return getObjectTypeId(report);
    }
    //TODO: is there a better way to handle this exception?
    if (dataType === ENGAGEMENT) {
      return ENGAGEMENT_OBJECT_ID;
    }
    return DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(dataType);
  })();
  const dataSourceId = objectTypeId || dataType;
  return dataSourceId ? [dataSourceId] : [];
};
export const getReportDataSources = ({
  report
}) => {
  if (isRelationalReport(report)) {
    return getSnowflakeReportDataSources(report);
  }
  return getConfigReportDataSources(report);
};
export const isCommercePaymentsReport = ({
  report
}) => {
  const COMMERCE_PAYMENTS_ID = ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.get(COMMERCE_PAYMENTS);
  const reportDataSources = getReportDataSources({
    report
  }); // ['0-1', '0-101']
  return reportDataSources.includes(COMMERCE_PAYMENTS_ID);
};
const SNOWFLAKE_REPORT_TEMPLATE_KEY_EXCEPTIONS = ['customer-payments-by-month',
// Paying customers by month
'bottom-10-line-items-by-total-payment-sales',
// Gross payment revenue from lowest selling products
'gross-revenue-split-by-payment-type',
// Gross payment revenue split by payment type over time
'gross-payment-revenue-by-quarter',
// Gross payment revenue by quarter
'gross-payment-revenue-by-current-month',
// Gross payment revenue by current month
'top-10-one-time-line-items-by-total-payment-sales',
// Gross payment revenue from top selling products with one-time payment
'gross-payment-revenue-by-current-year',
// Gross payment revenue by current year
'top-payment-sales-performers-by-current-quarter',
// Gross payment revenue top reps by current quarter
'bottom-10-line-item-total-sales-by-payment-type',
// Gross payment revenue from lowest selling products with payment breakdown
'top-10-recurring-line-items-by-total-payment-sales' // Gross payment revenue from top selling products with recurring payments
];
export const isSnowflakeReportTemplateException = report => {
  const templateKey = getTemplateKey(report);
  return isRelationalReport(report) && !isCustom(report) && SNOWFLAKE_REPORT_TEMPLATE_KEY_EXCEPTIONS.includes(templateKey);
};