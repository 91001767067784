import styled from 'styled-components';
import Heading from '../Heading';
const H3 = styled(Heading).withConfig({
  displayName: "H3",
  componentId: "w4s74d-0"
})([""]);
H3.propTypes = Heading.propTypes;

/* eslint-disable-next-line hubspot-dev/no-default-props-assignment */
H3.defaultProps = Object.assign({}, Heading.defaultProps, {
  tagName: 'h3'
});
H3.displayName = 'H3';
export default H3;