import Raven from 'raven-js';
import { calculatePortalFeatureLoss, getParcelsFromProductApiNames } from '../utils/parcelUtils';
import { SORTED_PRO_FEATURE_LIMIT_NAMES, SORTED_STARTER_FEATURE_LIMIT_NAMES } from '../constants/featureLimits';
import { useFeatureUsageMappings } from './useFeatureUsage';
import { useSubscriptions } from './useSubscriptions';
const FREE_PARCEL = 'hubspot-free';
/**
 * useFetchFeatureLoss
 *
 * Fetches feature loss data based on the current and downgraded subscription tiers.
 *
 * @param {SubscriptionType[]} paidSubscriptions - List of paid subscriptions.
 * @param {CurrentTier} currentTier - The current subscription tier.
 * @param {DowngradedTier} downgradedTier - The downgraded subscription tier.
 * @param {UseFetchFeatureLossOptions} [options] - Optional configuration object.
 * @param {boolean} [options.skipRequest] - Whether to skip the request.
 * @param {SubscriptionType[]} [options.allSubscriptions] - List of all subscriptions. Defaults to paidSubscriptions.
 * @param {DataFetchingClient} [options.client] - Optional data fetching client.
 * @param {boolean} [options.subscriptionLoading] - Whether subscriptions are still loading.
 * @param {boolean} [options.includeZeroAndNoLossUsage] - Whether to include zero or non-loss usage.
 *
 * @returns {{ portalFeatureLoss: FeatureUsage[], loading: boolean, error?: Error }} - The feature loss data, loading state, and optional error.
 */
export const useFetchFeatureLoss = (productApiNames, currentTier, downgradedTier, options = {}) => {
  const {
    allProductApiNames = productApiNames,
    skipRequest = false,
    client,
    subscriptionLoading = false,
    includeZeroAndNoLossUsage = false
  } = options;
  const productApiNamesForSpecifiedTier = productApiNames.filter(productApiName => productApiName.includes(currentTier));
  const currentPaidParcels = getParcelsFromProductApiNames(productApiNamesForSpecifiedTier);
  const allPaidParcels = getParcelsFromProductApiNames(allProductApiNames);
  const {
    data: featureUsage,
    loading,
    error
  } = useFeatureUsageMappings(skipRequest, client);
  if (error) {
    Raven.captureMessage('An error happened while retrieving feature usage data', {
      extra: {
        error
      }
    });
    return {
      portalFeatureLoss: [],
      loading,
      error
    };
  }

  // We don't have prio list for enterprise tier, so we use pro prio list for now
  const featureLimitNamesByPriority = currentTier === 'starter' ? [...SORTED_STARTER_FEATURE_LIMIT_NAMES] : [...SORTED_PRO_FEATURE_LIMIT_NAMES];

  // All parcel limit versions associated for all subscriptions user own (including ones they are not cancelling)
  const allParcelLimitVersions = featureUsage.flatMap(usage => usage.limitByParcel.filter(({
    parcelName
  }) => allPaidParcels.includes(parcelName)));

  // Parcel limit versions associated to subscriptions user is cancelling
  // This keeps duplicate parcel limits for the same limitId (i.e. same limit but different parcel name)
  // See bottom of this file for example data
  const currentParcelLimitVersions = featureUsage.flatMap(usage => usage.limitByParcel.filter(({
    parcelName
  }) => currentPaidParcels.includes(parcelName) && parcelName.includes(currentTier)));

  // Parcel limit versions for the specified downgraded tier
  // This is under the assumption that limits are same for all parcels in the same tier
  // See bottom of this file for example data
  const downgradedParcelLimitVersions = featureUsage.flatMap(usage => usage.limitByParcel.filter(({
    parcelName
  }) => {
    if (downgradedTier === 'free') {
      return parcelName === FREE_PARCEL;
    } else {
      return parcelName.includes(downgradedTier);
    }
  }));

  // Parcel limit versions that user will retain after downgrading
  const retainedParcelLimitVersions = allParcelLimitVersions.filter(limitVersion => {
    return currentParcelLimitVersions.every(currentLimitVersion => currentLimitVersion.parcelName !== limitVersion.parcelName);
  });
  const portalFeatureLoss = calculatePortalFeatureLoss(featureUsage, currentParcelLimitVersions, downgradedParcelLimitVersions, retainedParcelLimitVersions, featureLimitNamesByPriority, includeZeroAndNoLossUsage, productApiNamesForSpecifiedTier);
  return {
    portalFeatureLoss,
    loading: loading || subscriptionLoading
  };
};
export const useFetchFeatureLossWithSubscriptions = (productApiNames, currentTier, downgradedTier, skipRequest = false) => {
  const {
    data: subscriptions,
    loading: subscriptionLoading
  } = useSubscriptions();
  const filteredProductApiNames = subscriptions.flatMap(subscription => subscription.products.flatMap(product => product.productApiName)).filter(apiName => apiName !== null);
  return useFetchFeatureLoss(filteredProductApiNames, currentTier, downgradedTier, {
    skipRequest,
    allProductApiNames: filteredProductApiNames,
    subscriptionLoading
  });
};

/* Example (downgradedTier = 'starter', currentPaidParcels = ['sales-pro-bundle']):
    featureUsage = [{
      limitId: 6,
      limitName: 'dashboards',
      usage: 5200,
      limitByParcel: [
        {
          limitId: 6,
          limit: 5000,
          parcelName: 'marketing-starter',
          displayName: '',
        },
        {
          limitId: 6,
          limit: 10000,
          parcelName: 'sales-pro-bundle',
          displayName: '',
        },
        {
          limitId: 6,
          limit: 5000,
          parcelName: 'sales-starter-bundle',
          displayName: '',
        },
      ],
    },
    {
      limitId: 77,
      limitName: 'email_sends',
      usage: 345,
      limitByParcel: [
        {
          limitId: 77,
          limit: 5000,
          parcelName: 'service-professional',
          displayName: '',
        },
        {
          limitId: 77,
          limit: 100,
          parcelName: 'marketing-starter',
          displayName: '',
        },
        {
          limitId: 77,
          limit: 5000,
          parcelName: 'sales-pro-bundle',
          displayName: '',
        },
        {
          limitId: 77,
          limit: 100,
          parcelName: 'sales-starter-bundle'
          displayName: '',
        },
      ]
    }
  ]

   // Can include duplicates for the same limitId (i.e. same limit but different parcel name)
   currentParcelLimitVersions = [
       {
          limitId: 6,
          limit: 10000,
          parcelName: 'sales-pro-bundle',
          displayName: '',
        },
        {
          limitId: 77,
          limit: 5000,
          parcelName: 'service-professional',
          displayName: '',
        },
        {
          limitId: 77,
          limit: 5000,
          parcelName: 'sales-pro-bundle',
          displayName: '',
        }
    ]

    downgradedParcelLimitVersions = [
        {
          limitId: 6,
          limit: 5000,
          parcelName: 'marketing-starter',
          displayName: '',
        },
        {
          limitId: 6,
          limit: 5000,
          parcelName: 'sales-starter-bundle',
          displayName: '',
        },
        {
          limitId: 77,
          limit: 100,
          parcelName: 'marketing-starter',
          displayName: '',
        },
        {
          limitId: 77,
          limit: 100,
          parcelName: 'sales-starter-bundle',
          displayName: '',
        }
    ]
*/