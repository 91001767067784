'use es6';

import toJS from 'transmute/toJS';
import fromJS from 'transmute/fromJS';
import compose from 'transmute/compose';
import merge from 'transmute/merge';
import set from 'transmute/set';
import setIn from 'transmute/setIn';
import getIn from 'transmute/getIn';
import { List } from 'immutable';
import { ATTRIBUTION, ATTRIBUTION_TOUCH_POINTS, CONTACT_CREATE_ATTRIBUTION, CONTACTS, CRM_OBJECT, CROSS_OBJECT, DEAL_CREATE_ATTRIBUTION, DEALS, ENGAGEMENT, EXTERNAL } from 'reporting-data/constants/dataTypes';
import { getDataType } from 'reporting-data/report/reportGetters';
import { COMMERCE_PAYMENTS } from 'reporting-data/constants/dataTypes/inboundDb';
import { CUSTOM } from 'reporting-data/constants/chartTypes';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES, HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE } from 'reporting-data/constants/objectCoordinates';
import { supportedDataTypes } from 'reporting-data/retrieve/inboundDb/common/dataTypes';
import { getUserAddedQuickFilters } from 'reporting-data/report/reportEditorsGetters';
import { SourceTypes } from 'reporting-snowflake/relational/schema/table-records';
import { getTableNames } from 'reporting-snowflake/relational/utils/table-utils';
import { DataSourceTypes as SnowflakeDataSourceTypes } from 'reporting-snowflake/relational/schema/source-records';
import { ENGAGEMENT as ENGAGEMENT_OBJECT_ID } from 'reporting-snowflake/relational/metadata/objectTypes';
import { hasSufficientCreatePermission, hasSufficientEditPermission, hasSufficientViewPermission } from '../permissions/permission-logic';
import { getConfigCustomized, getObjectTypeId, getReportDefinition, getReportDashboardInfo, getReportDataType, getUserReportPermissionLevel, getCustomWidgetType, getChartType, DATA_LAYER, getCustomized, getSnowflakeVisualType, getJourneyQuery, getConfig, getConfigType, getTemplateKey, getSnowflakeReportDataSources, getSnowflakeReportTableDescription, getJourneyObjectTypeId, getJourneyTableName } from '../data/schemas/reportSchema';
import { ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS } from 'reporting-data/crmObjects/utils';
import { CONFIG, REPORT_DEFINITION, JOURNEY_QUERY } from 'reporting-data/constants/reportResolveType';
import { IFRAME, NOTE } from 'reporting-data/constants/dashboardWidgetType';
const SNOWFLAKE_REPORT_TEMPLATE_KEY_EXCEPTIONS = ['customer-payments-by-month',
// Paying customers by month
'bottom-10-line-items-by-total-payment-sales',
// Gross payment revenue from lowest selling products
'gross-revenue-split-by-payment-type',
// Gross payment revenue split by payment type over time
'gross-payment-revenue-by-quarter',
// Gross payment revenue by quarter
'gross-payment-revenue-by-current-month',
// Gross payment revenue by current month
'top-10-one-time-line-items-by-total-payment-sales',
// Gross payment revenue from top selling products with one-time payment
'gross-payment-revenue-by-current-year',
// Gross payment revenue by current year
'top-payment-sales-performers-by-current-quarter',
// Gross payment revenue top reps by current quarter
'bottom-10-line-item-total-sales-by-payment-type',
// Gross payment revenue from lowest selling products with payment breakdown
'top-10-recurring-line-items-by-total-payment-sales' // Gross payment revenue from top selling products with recurring payments
];
export const isSnowflake = report => getReportDefinition(report) || false;
export const isNoteWidget = report => getConfigType(report) === NOTE;
export const isIframeWidget = report => getConfigType(report) === IFRAME;
export const isDashboardOnlyContentWidget = report => isNoteWidget(report) || isIframeWidget(report);
export const isConfigReport = report => getConfig(report) && !isDashboardOnlyContentWidget(report);
export const isJourney = report => getJourneyQuery(report) || false;
export const isContactAttributionReport = report => getReportDataType(report) === ATTRIBUTION;
export const isRevenueAttributionReport = report => getReportDataType(report) === ATTRIBUTION_TOUCH_POINTS;
export const isContactCreateAttributionReport = report => getReportDataType(report) === CONTACT_CREATE_ATTRIBUTION;
const DEAL_CREATE_ATTRIBUTION_OBJECT_TYPE_ID = DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(DEAL_CREATE_ATTRIBUTION);
export const isDealCreateAttributionReport = report => getReportDataType(report) === CRM_OBJECT && getObjectTypeId(report) === DEAL_CREATE_ATTRIBUTION_OBJECT_TYPE_ID;
export const isAttributionLike = report => isContactAttributionReport(report) || isRevenueAttributionReport(report) || isContactCreateAttributionReport(report) || isDealCreateAttributionReport(report);
export const isJourneyLikeExternal = report => getDataType(report) === 'EXT_SMS_ANALYZE_FUNNEL_CHART';
export const isContactJourney = report => isJourney(report) && getJourneyObjectTypeId(report) === DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(CONTACTS);
export const isDealJourney = report => isJourney(report) && getJourneyObjectTypeId(report) === DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(DEALS);

// checks whether a report is a custom widget, NOT whether it is a custom report (counted against limit)
export const isCustomWidget = report => getChartType(report) === CUSTOM || getCustomWidgetType(report) !== DATA_LAYER;

// copied from reporting-ui-components, then modified to use reportSchema functions & account for sales analytics custom widgets
export const isEditableInBuilder = report => {
  if (!report) return false;
  const dataType = getReportDataType(report);
  const hasSupportedDataType = supportedDataTypes.includes(dataType);
  const isCrossObject = dataType === CROSS_OBJECT;
  const hasQuotaMetric = report.getIn(['config', 'metrics'], List()).some(metric => metric.get('property') === 'QUOTAS.MONTHLY.REVENUE');
  return !isCustomWidget(report) && (hasSupportedDataType || isCrossObject) && !hasQuotaMetric;
};
export const isEditableReport = report => isAttributionLike(report) || isSnowflake(report) || isJourney(report) || isEditableInBuilder(report) || isJourneyLikeExternal(report);
export const isCustom = report => {
  return getCustomized(report) || getConfigCustomized(report) || false;
};

// Identifies the only standard snowflake reports that are available to portals
// without custom reporting access (only Starter for now).
export const isSnowflakeReportTemplateException = report => {
  const templateKey = getTemplateKey(report);
  return isSnowflake(report) && !isCustom(report) && SNOWFLAKE_REPORT_TEMPLATE_KEY_EXCEPTIONS.includes(templateKey);
};
export const isStandaloneReport = report => getReportDashboardInfo(report).length === 0;
const cleanPathname = pathname => pathname.replace(/\d+/g, '*');
export const getWindowPathname = () => {
  const {
    pathname
  } = window.location;
  return fromJS({
    pathname: cleanPathname(pathname)
  });
};
export const getOrigin = report => {
  const pathname = getIn(['displayParams', 'origin', 'pathname'], report);
  return pathname ? cleanPathname(pathname) : undefined;
};
export const stripNonUserAddedEditors = report => report.set('editors', getUserAddedQuickFilters(report));
export const prepareReportForSaving = (report, reportName, reportDescription, aiGeneratedDescription, reportPermissionConfig = {}) => compose(setIn(['displayParams', 'origin'], getWindowPathname()), set('name', reportName), set('description', reportDescription), set('aiGeneratedDescription', aiGeneratedDescription), stripNonUserAddedEditors, merge(reportPermissionConfig), fromJS, toJS)(report);
export const isDashboardCompatible = report => getReportDataType(report) !== ATTRIBUTION;
export const isSalesAnalyticsCustomWidget = report => report.hasIn(['displayParams', 'salesAnalytics', 'subAppKey']) && getCustomWidgetType(report) !== 'DATA_LAYER';
export const isCustomDatasetReport = report => report.getIn(['reportDefinition', 'table', 'type']) === SourceTypes.HUBSPOT_DATASET;
export const hasSufficientReportViewPermission = report => hasSufficientViewPermission(getUserReportPermissionLevel(report));
export const hasSufficientReportEditPermission = report => hasSufficientEditPermission(getUserReportPermissionLevel(report));
export const hasSufficientReportCreatePermission = report => hasSufficientCreatePermission(getUserReportPermissionLevel(report));
export const setLimit = (limit, report) => !isSnowflake(report) ? setIn(['config', 'limit'], limit, report) : report;
export const isDatasetsReport = report => {
  if (!isSnowflake(report)) {
    return false;
  }
  const tableDescription = getSnowflakeReportTableDescription(report);
  return tableDescription.type === SnowflakeDataSourceTypes.HUBSPOT_DATASET;
};
export const getReportDataTypesForTracking = report => {
  if (isSnowflake(report)) {
    const tableDescription = getSnowflakeReportTableDescription(report);
    return getTableNames(tableDescription).toArray();
  } else if (isJourney(report)) {
    return [getJourneyTableName(report)];
  }
  const dataType = getReportDataType(report);
  const objectTypeId = dataType === CRM_OBJECT && getObjectTypeId(report);
  const crmObjectDataType = objectTypeId && HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE.get(objectTypeId);
  const dataTypeForTracking = crmObjectDataType || dataType;
  return [dataTypeForTracking];
};
export const getReportVisualType = report => isSnowflake(report) ? getSnowflakeVisualType(report) : getChartType(report);
export const getReportDataSources = ({
  report
}) => {
  if (isSnowflake(report)) {
    return getSnowflakeReportDataSources(report);
  }
  const dataType = getReportDataType(report);
  if (dataType === EXTERNAL) {
    return [getDataType(report)];
  }
  const objectTypeId = dataType === CRM_OBJECT ? getObjectTypeId(report) : dataType === ENGAGEMENT //TODO: is there a better way to handle this exception?
  ? ENGAGEMENT_OBJECT_ID : DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(dataType);
  const dataSourceId = objectTypeId || dataType;
  return dataSourceId ? [dataSourceId] : [];
};
export const isCommercePaymentsReport = ({
  report
}) => {
  const COMMERCE_PAYMENTS_ID = ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.get(COMMERCE_PAYMENTS);
  const reportDataSources = getReportDataSources({
    report
  }); // ['0-1', '0-101']
  return reportDataSources.includes(COMMERCE_PAYMENTS_ID);
};
export const getResolveTypeFromReport = report => {
  const isConfig = isConfigReport(report);
  const isReportDefinitionReport = isSnowflake(report);
  const isJourneyReport = isJourney(report);
  return isConfig ? CONFIG : isReportDefinitionReport ? REPORT_DEFINITION : isJourneyReport ? JOURNEY_QUERY : undefined;
};
export const getWidgetTypeFromReport = report => {
  if (!isConfigReport(report)) {
    return undefined;
  }
  return isNoteWidget(report) ? NOTE : isIframeWidget(report) ? IFRAME : undefined;
};