import styled from 'foundations-theming/utils';
export const StyledIcon = styled.svg.withConfig({
  displayName: "StyledIcon",
  componentId: "sc-1fmz1la-0"
})(({
  $size,
  $color,
  $alignWithText
}) => {
  return Object.assign({
    inlineSize: $size,
    blockSize: $size,
    fill: $color
  }, $alignWithText && {
    verticalAlign: '-0.1em' // This value was chosen based on the icon svg alignment within its viewbox
  });
});