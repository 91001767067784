// Product to parcels map is taken from https://tools.hubteam.com/merchandiseui/products/

export const productApiNameToParcelMap = {
  // Free
  'hubspot-free': ['hubspot-free'],
  // Starter Products
  'crm-suite-starter-bundle': ['core-seats', 'seats-access', 'marketing-starter', 'content-hub-starter', 'service-starter-bundle', 'sales-starter-bundle', 'ops-starter'],
  'marketing-hub-starter': ['core-seats', 'seats-access', 'marketing-starter'],
  'service-hub-starter': ['core-seats', 'seats-access', 'service-starter-bundle'],
  'cms-hub-starter': ['core-seats', 'seats-access', 'content-hub-starter'],
  'sales-hub-starter': ['core-seats', 'seats-access', 'sales-starter-bundle'],
  'operations-hub-starter': ['core-seats', 'seats-access', 'ops-starter'],
  // Professional Products
  'crm-suite-professional-bundle': ['core-seats', 'seats-access', 'marketing-pro', 'sales-pro-bundle', 'service-professional-bundle', 'ops-pro', 'content-hub-professional	'],
  'marketing-hub-professional': ['core-seats', 'seats-access', 'marketing-pro'],
  'marketing-plus-professional-bundle': ['core-seats', 'seats-access', 'marketing-pro', 'content-hub-professional	'],
  'sales-hub-professional': ['seats-access', 'sales-pro-bundle'],
  'service-hub-professional': ['seats-access', 'service-professional-bundle'],
  'operations-hub-professional': ['core-seats', 'seats-access', 'ops-pro'],
  'cms-hub-professional': ['core-seats', 'seats-access', 'content-hub-professional'],
  // Enterprise Products
  'crm-suite-enterprise-bundle': ['core-seats', 'seats-access', 'marketing-enterprise', 'service-enterprise-bundle', 'ops-enterprise', 'content-hub-enterprise', 'sales-enterprise-bundle'],
  'marketing-hub-enterprise': ['core-seats', 'seats-access', 'marketing-enterprise'],
  'service-hub-enterprise': ['seats-access', 'service-enterprise-bundle'],
  'operations-hub-enterprise': ['core-seats', 'seats-access', 'ops-enterprise'],
  'cms-hub-enterprise': ['core-seats', 'seats-access', 'content-hub-enterprise'],
  'sales-hub-enterprise': ['seats-access', 'sales-enterprise-bundle']
};