import { useCallback, useEffect, useMemo, useState } from 'react';
import { isStarted, buildFailedAsyncData, buildStartedAsyncData, buildSucceededAsyncData, buildUnitializedAsyncData, isFailed, isSucceeded, isUninitialized } from 'reporting-data/asyncData/AsyncData';
import { useDataSourceMetaWithLoading } from 'platform-dashboard-ui/hooks/use-data-source-metadata';
import { useDataSourceProperties } from 'platform-dashboard-ui/hooks/use-data-source-properties';
import { getDashboardDataSources } from 'platform-dashboard-ui/lib/dashboardDataSourceUtils';
import { fetchSavedFilterById } from './api';
import { useSelector } from 'react-redux';
import { transformSavedFilterToDashboardFilter } from './utils';
import { selectQuickFilterGroups } from '../../ducks/quick-filters';
import { QUICK_FILTER_TEMPLATES, isDateRangeGeneratedQuickFilterGroup } from '../dashboardFilters/groupQuickFilterHelpers';
import { DYNAMIC_DATE_RANGE_PROPERTY_NAME } from '../dashboardFilters/types';
import { fetchGeneratedQuickFilterGroups } from 'platform-dashboard-ui/filter/data/QuickFilterDAO';
const fetchDateRangeProperties = async dashboardId => {
  const generatedQuickFilterGroup = await fetchGeneratedQuickFilterGroups(dashboardId, [QUICK_FILTER_TEMPLATES.DATE_RANGE]);
  const dateRangeGroup = generatedQuickFilterGroup.find(isDateRangeGeneratedQuickFilterGroup);
  return (dateRangeGroup === null || dateRangeGroup === void 0 ? void 0 : dateRangeGroup.properties) || [];
};
export const useFetchEmailOnlyFilterSet = (dashboard, filterSetId) => {
  const dashboardId = dashboard.id;
  const [filterSetAsyncData, setFilterSetAsyncData] = useState(buildUnitializedAsyncData());
  const dataSourceIds = Array.from(new Set((isSucceeded(filterSetAsyncData) ? filterSetAsyncData.data.filters.map(f => f.dataSourceId) : []).concat(getDashboardDataSources(dashboard)).filter(ds => !!ds)));
  const {
    dataSourceMetaData,
    isLoading: isDataSourceMetaLoading
  } = useDataSourceMetaWithLoading(dataSourceIds);
  const {
    propertiesByDataSource,
    isLoading: isPropertyMetaLoading,
    isResolved: isDataSourcePropertiesResolved
  } = useDataSourceProperties(dataSourceIds);
  const selectedQuickFilterGroups = useSelector(selectQuickFilterGroups);
  const quickFilterGroups = selectedQuickFilterGroups.length > 0 && isDataSourcePropertiesResolved ? selectedQuickFilterGroups : dashboard.filterGroups || [];
  const maybeDynamicDateRangeGroup = quickFilterGroups.find(isDateRangeGeneratedQuickFilterGroup);
  const [dynamicDateRangeProperties, setDynamicDataRangeProperties] = useState(maybeDynamicDateRangeGroup ? maybeDynamicDateRangeGroup.properties : []);
  const emailFilters = useMemo(() => {
    if (isSucceeded(filterSetAsyncData) && !isDataSourceMetaLoading && !isPropertyMetaLoading) {
      return transformSavedFilterToDashboardFilter(filterSetAsyncData.data.filters, propertiesByDataSource, dataSourceMetaData, dynamicDateRangeProperties);
    }
    return [];
  }, [filterSetAsyncData, propertiesByDataSource, isPropertyMetaLoading, dataSourceMetaData, isDataSourceMetaLoading, dynamicDateRangeProperties]);
  const handleFetchFilterSet = useCallback(async filterId => {
    setFilterSetAsyncData(buildStartedAsyncData());
    try {
      const response = await fetchSavedFilterById(filterId);
      const hasDynamicDateRangeFilter = response.filters.some(f => f.name === DYNAMIC_DATE_RANGE_PROPERTY_NAME);
      if (hasDynamicDateRangeFilter && !maybeDynamicDateRangeGroup) {
        const dataRangeProperties = await fetchDateRangeProperties(dashboardId);
        setDynamicDataRangeProperties(dataRangeProperties);
      }
      setFilterSetAsyncData(buildSucceededAsyncData(response));
    } catch (err) {
      setFilterSetAsyncData(buildFailedAsyncData(err));
    }
  }, [dashboardId, maybeDynamicDateRangeGroup, setDynamicDataRangeProperties]);
  useEffect(() => {
    if (filterSetId && isUninitialized(filterSetAsyncData)) {
      handleFetchFilterSet(filterSetId).catch(console.error);
    }
  }, [filterSetId, handleFetchFilterSet, filterSetAsyncData]);
  const isLoading = isStarted(filterSetAsyncData);
  return {
    isLoading,
    emailFilters,
    hasLoaded: isSucceeded(filterSetAsyncData),
    hasError: isFailed(filterSetAsyncData),
    frequency: isSucceeded(filterSetAsyncData) ? filterSetAsyncData.data.frequency : undefined,
    useFiscalYear: isSucceeded(filterSetAsyncData) ? filterSetAsyncData.data.useFiscalYear : undefined
  };
};